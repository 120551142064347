<template>
  <v-card elevation="4" min-width="600" class="mx-auto my-auto">
    <v-card-title>Login VAO</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field label="E-Mail" type="email" v-model="credentials.email"></v-text-field>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field type="password" label="Passwort" v-model="credentials.password"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

        <v-alert v-if="showError" type="error">Die Kombination aus E-Mail und Passwort stimmt nicht überein.</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" text @click="login()">Anmelden</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Login',
  data: () => ({
      credentials: {
          email: '',
          password: ''
      },
      showError: false
  }),
    methods: {
      login() {
          const vue = this
          if (this.credentials.email !== '' && this.credentials.password !== '') {
              this.$store.dispatch("auth/login", this.credentials).catch(function () {
                  vue.showError = true
              })
          } else {
              this.showError = true
          }
      }
    }
}
</script>
