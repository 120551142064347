<template>
  <v-navigation-drawer app clipped>
    <v-list dense nav>
      <template v-for="item in items">
        <v-list-item
            v-if="item.subItems.length === 0"
            :key="item.title"
            :to="{ name: item.routeName }"
        >
          <v-list-item-icon class="text-center">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
            v-else
            no-action
            :key="item.title"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
              v-for="subitem in item.subItems"
              :key="subitem.title"
              :to="{ name: subitem.routeName }"
              :sub-group="false"
          >
            <v-list-item-icon class="text-center">
              <v-icon>{{ subitem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
    <v-footer absolute padless>
      <v-card flat tile width="100%" class="accent lighten-1 text-center">
        <v-card-text>
          {{ version }} — VAO
        </v-card-text>
      </v-card>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import { version } from "../../package";

export default {
  // eslint-disable-next-line
  name: 'Navigation',
  data: () => ({
    version: version,
    items: [
      {
        title: "Übersicht",
        icon: "mdi-home",
        routeName: "dashboard",
        subItems: [],
      },
      {
        title: "Institutionen",
        routeName: "institution",
        icon: "mdi-domain",
        subItems: [],
      },
      {
        title: "Orte",
        routeName: "place",
        icon: "mdi-map-marker",
        subItems: [],
      },
      {
        title: "Veranstaltungen",
        routeName: "event",
        icon: "mdi-calendar-multiple",
        subItems: [],
      }/*,
      {
        title: "Einstellungen",
        routeName: "event",
        icon: "mdi-gear",
        subItems: [
          {
            title: "Veranstaltungen",
            routeName: "event",
            icon: "mdi-calendar-multiple",
            subItems: [],
          },
        ],
      },*/
    ]
  }),
};
</script>

<style lang="scss" scoped>
.v-list-group--no-action > .v-list-group__items > .v-list-item {
  padding-left: 0.5rem !important;
}
footer a {
  text-decoration: none;
}
</style>
