<template>
  <v-app>
    <template v-if="isAuthenticated">
      <AppBar></AppBar>
      <Navigation></Navigation>
      <v-main>
        <router-view></router-view>
      </v-main>
    </template>
    <template v-else>
      <Login></Login>
    </template>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import Login from "@/components/Login";
import Navigation from "@/components/Navigation";

export default {
  name: 'App',

  components: {
    Navigation,
    Login,
    AppBar,
  },
    computed: {
        isAuthenticated: function() {
            return this.$store.getters["auth/isAuthenticated"];
        },
    },

  data: () => ({
    isLoggedIn: false
  }),
    methods: {
      syncData() {
          this.$store.dispatch('places/loadAll')
          this.$store.dispatch('user/loadAll')
          this.$store.dispatch('institutions/loadAll')
          this.$store.dispatch('booths/loadAll')
          this.$store.dispatch('events/loadAll')
      }
    },
    watch: {
        isAuthenticated: function(val) {
            if (val === true) {
                this.syncData();
            }
        }
    }
};
</script>
