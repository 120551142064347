<template>
    <v-app-bar app clipped-left>
        <v-app-bar-title>VAO</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showAccountDialog = true">
            <v-icon>mdi-account</v-icon>
        </v-btn>
        <v-btn icon @click="logout()">
            <v-icon>mdi-logout</v-icon>
        </v-btn>
        <v-dialog v-model="showAccountDialog" max-width="600">
            <AccountEditCard :account-id="user.id" v-on:cancel="showAccountDialog = false">
            </AccountEditCard>
        </v-dialog>
    </v-app-bar>
</template>

<script>
import AccountEditCard from "@/views/account/AccountEditCard.vue";

export default {
    name: 'AppBar',
    components: {AccountEditCard},
    computed: {
        user () {
            return this.$store.getters['auth/currentUser']
        }
    },
    data: () => ({
        header: [
            []
        ],
        account: {
            firstname: '',
            lastname: ''
        },
        showAccountDialog: false
    }),
    methods: {
        logout() {
            this.$store.dispatch('auth/logout')
        }
    }
}
</script>
